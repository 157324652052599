.App {
  text-align: center;
}

.App-logo {
  height: 10vmin;
  pointer-events: none;
}




.App-link {
  color: #61dafb;
}



.glowable-text {
  color: #1e1e1e;
  ;
}

[glow] .glowable-text {
  color: var(--glow-color);
}


